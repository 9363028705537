<template>
	<main>
		<component
      v-if="story.content.component"
      :page_name="story.name"
      :page_id="story.id"
      :page_uid="story.uuid"
      :full_slug="story.full_slug"
      :key="story.content._uid"
      :blok="story.content"
      :is="story.content.component | dashify"
    />
    <client-only>
      <cookie-law
        theme="goede"
        buttonText="Accepteer cookies"
        v-on:accept="cookiesAccepted()"
      >
        <div slot="message">
          Deze website gebruikt cookies om het gebruik van de website te analyseren en het gebruiksgemak te verbeteren. <a href="/cookies" target="_blank">Lees meer</a>
        </div>
      </cookie-law>
    </client-only>
	</main>
</template>

<script>
	const loadData = ({api, cacheVersion, errorCallback, version, path}) => api.get(`cdn/stories/${path}`, {
		version: version,
		cv: cacheVersion
	}).then((res) => {
		return res.data
	}).catch((res) => {
		if (!res.response) {
			console.error(res);
			errorCallback({statusCode: 404, message: 'Failed to receive content form api'})
		} else {
			console.error(res.response.data);
			errorCallback({statusCode: res.response.status, message: res.response.data})
		}
	});

	const loadBreadcrumbs = async ({api, version, cacheVersion, story}) => {
		let slug = '';
		let items = [];

		// Home
		items.push({
			text: 'Home',
			exact: true,
			to: '/',
		})

		let names = story.full_slug.replace(/^\/|\/$/g, '').split('/');
		for (let name of names) {
			slug = slug + '/' + name;

			try {
        const res = await api.get(`cdn/stories${slug}`, {
          'version': version,
          'cv': cacheVersion
        });
        if (res.data.story) {
          items.push({
	          text: res.data.story.name,
            to: '/' + res.data.story.full_slug.replace('home', '').replace(/^\/|\/$/g, ''),
            exact: true
          })
        }
      } catch (e) {
        console.warn(`Page doesn't exist for: "${slug}"`)
      }
		}

		return items;
	};

	import CookieLaw from 'vue-cookie-law'
  import _ from 'lodash'

	const structuredData = {
		"@context": "http://www.schema.org",
		"@type": "LocalBusiness",
		"name": "Avontuurlijk spelen - Goede Speelprojecten",
		"url": "https://avontuurlijkspelen.com",
		"logo": "https://avontuurlijkspelen.com/logo.svg",
		"image": "https://avontuurlijkspelen.com/office.jpg",
		"address": {
			"@type": "PostalAddress",
			"streetAddress": "Kraaivenstraat 23-12",
			"addressLocality": "Tilburg",
			"addressRegion": "Noord-Brabant",
			"postalCode": "5018 AB",
			"addressCountry": "Nederland"
		},
		"openingHours": "Mo, Tu, We, Th 08:30-17:00 Fr 08:30-15:00",
		"contactPoint": {
			"@type": "ContactPoint",
			"telephone": "+31 13 455 16 05",
			"contactType": "sales"
		}
	}

	export default {
		data() {
			return {story: {content: {}}}
		},
    components: {CookieLaw},
		methods: {
			cookiesAccepted() {
				// noinspection JSDeprecatedSymbols
				window.location.reload(true)
			}
		},
		mounted() {
			this.$storybridge.on(['input', 'published', 'change'], (event) => {
				if (event.action === 'input') {
					if (event.story.id === this.story.id) {
						this.story.content = event.story.content
					}
				} else {
					if (event.storyId === this.story.id) {
						loadData({
							version: 'draft',
							api: this.$storyapi,
							cacheVersion: this.$store.state.cacheVersion,
							errorCallback: console.error,
							path: this.story.full_slug
						}).then((data) => {
							this.story = data.story
						})
					} else {
						window.location.reload()
					}
				}
			})
		},
		async asyncData(context) {
			// Check if we are in the editing mode
			let editMode = false;

			if (context.query._storyblok || (typeof window !== 'undefined' && context.store.state.draftMode)) {
				context.store.commit('setDraftMode', true);
				if (typeof window !== 'undefined' && window.location === window.parent.location) {
					context.store.commit('setDraftMode', false);
				}

				editMode = true
			}

			let version = editMode ? 'draft' : 'published';
			let path = context.route.path === '/' ? 'home' : context.route.path;

			// Load the JSON from the API
			let data = await loadData({
				api: context.app.$storyapi,
				version: version,
				cacheVersion: context.store.state.cacheVersion,
				errorCallback: context.error,
				path: path
			});

			// Load breadcrumbs and product category links
			let breadcrumbs = [];
			let productCategoryLinks = [];
			if (data) {
				breadcrumbs = await loadBreadcrumbs({
					api: context.app.$storyapi,
					version: version,
					cacheVersion: version,
					story: data.story
				});

				if (
					data.story.content.component === 'page_product_category_overview' ||
					data.story.content.component === 'page_product_overview'
        ) {
          const links = _.cloneDeep(context.store.state.links);
          const parentSlug = `${(_.filter((data.story.full_slug).split('/')).slice(0, 2)).join('/')}/`
					const startsWith = `${(data.story.full_slug).split('/')[0]}/`;
					const subTree = [];
					const startPages = _.filter(links, (link) => {
						return link.is_startpage && _.startsWith(link.slug, startsWith)
					})
          const folders = {}
          _.each(
            _.filter(links, (link) => {
	            return link.is_folder && _.startsWith(link.slug, startsWith)
            }), folder => {
              folders[folder.id] = folder;
            }
          )

					_.each(startPages, (page) => {
						const path = _.filter(((page.slug).replace(startsWith, '')).split('/'))

						const toAdd = {
							slug: page.slug,
							title: page.name,
							uuid: page.uuid,
              position: folders[page.parent_id] ? folders[page.parent_id].position: page.position
						}

						if (path.length === 1) {
							productCategoryLinks.push(Object.assign(toAdd, {
								active: page.slug === parentSlug,
							}))
						} else if (path.length === 2) {
							subTree.push(Object.assign(toAdd, {
								parentSlug: `${startsWith}${path[0]}/`,
							}));
						}
					})

					_.each(subTree, (page) => {
						const index = _.findIndex(productCategoryLinks, (p) => { return p.slug === page.parentSlug; });

						if (index > -1) {
							(productCategoryLinks[index].children || (productCategoryLinks[index].children = [])).push(page)
						} else {
							productCategoryLinks.push(page)
						}
					})

          productCategoryLinks = _.orderBy(productCategoryLinks, ['position'], ['asc'])

          _.each(productCategoryLinks, (item, key) => {
          	if (item.children) {
		          productCategoryLinks[key].children = _.orderBy(item.children, ['position'], ['asc'])
            }
          })
				}
			}

      context.store.commit('setBreadcrumbs', breadcrumbs);
			context.store.commit('setProductCategoryLinks', productCategoryLinks);

			return data;
		},
		head() {
			let story = this.story;
			const data = {
				title: story.content.seo_title ? story.content.seo_title : 'Avontuurlijk spelen - Goede Speelprojecten - ' + story.name,
				meta: [
					{ name: 'description', content: story.content.seo_description ? story.content.seo_description : '' },
					{ name: 'robots', content: story.content.seo_indexable === 'yes' ? 'index, follow' : 'noindex, follow' },
					{ property: 'og:site_name', content: 'Avontuurlijk spelen - Goede Speelprojecten' },
					{ property: 'og:title', content: story.content.seo_og_title ? story.content.seo_og_title : 'Avontuurlijk spelen - Goede Speelprojecten - ' + story.name },
					{ property: 'og:description', content: story.content.seo_og_description ? story.content.seo_og_description : '' },
				],
				__dangerouslyDisableSanitizers: ['script'],
				script: [{ innerHTML: JSON.stringify(structuredData), type: 'application/ld+json' }]
			};

			if (story.content.og_image) {
				data.meta.push(
					{property: 'og:image', content: `//img2.storyblok.com/1500x0'${story.content.seo_og_image.replace('//a.storyblok.com', '')}`}
				)
			} else {
				data.meta.push(
					{property: 'og:image', content: `${process.env.BASE_URL}/opengraph/1200x628.jpg`},
					{property: 'og:image:width', content: '1200'},
					{property: 'og:image:height', content: '628'},
					{property: 'og:image', content: `${process.env.BASE_URL}/opengraph/1080x1920.jpg`},
					{property: 'og:image:width', content: '1080'},
					{property: 'og:image:height', content: '1920'},
				)
			}

			return data;
		}
	}
</script>
